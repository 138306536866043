<template>
  <div class="form">
    <div class="wrapper" v-if="!isMobile || !$store.state.popup.id && (!isMobile || !$store.state.is_popup_shown)">
      <Backlink title="questionnaire" service="refCredit" backlink="/refinance-credit/amount-credits" />
      <div class="content">
        <div class="content__container">
          <p class="promo promo_blocks2">&nbsp;</p>
          <div class="service__container">
            <button class="service service_refcredit service_refcredit_1" @click="select_price">
              <div class="service__title">
                {{ "rc_s4p15_text_1" | localize }}
              </div>
              <div class="service__subtitle">
                {{ price_1.toLocaleString() }} <span class="icon-₪"></span>
              </div>
            </button>
            <button class="service service_refcredit service_refcredit_2" @click="select_more">
              <div class="service__title">
                {{ "rc_s4p15_text_2" | localize }}
              </div>
            </button>
          </div>

          <form @submit.prevent="submitHandler">
            <p class="question" v-show="is_more">{{ "aim_text" | localize }}</p>
            <div class="form__group" v-show="is_more">
              <v-select
                v-model="aim"
                item-text="name"
                item-value="value"
                @change="showPopup"
                :items="getList('aim_select_', 4)"
                :label="'aim_label' | localize"
                :placeholder="'select' | localize"
                :menu-props="{ offsetY: true }"
                append-icon="icon-arrow-down"
                ref="aim"
                outlined
              ></v-select>
            </div>

            <p class="question">{{ "rc_s4p15_title" | localize }}</p>
            <div class="form__group">
              <v-text-field
                v-model="money"
                class="input_number"
                inputmode="numeric"
                pattern="[0-9]*"
                @blur="$v.money.$touch()"
                @input="checkForm($v.money)"
                @keypress="onKeyPressHandler"
                @keyup="onKeyUpPriceHandler('money', money)"
                :class="{ invalid: $v.money.$dirty && !$v.money.required }"
                :hint="
                  ($v.money.$dirty && !$v.money.required
                    ? 'form_reqiered'
                    : 'empty') | localize
                "
                :placeholder="'c_s1p1_placeholder' | localize"
                append-icon="icon-₪"
                ref="money"
                outlined
              ></v-text-field>
            </div>

            <div class="error__container">
              <p class="error" v-if="error">{{ error | localize }}</p>
            </div>

            <button
              type="button"
              class="button button__form"
              :class="{ button__form_disabled: !success }"
              @click="submitHandler"
            >
              {{ "form_continue" | localize }}
            </button>
          </form>
        </div>
      </div>
    </div>

    <div class="popup popup_active popup_info" v-if="$store.state.popup.id">
      <div class="popup__overlay popup__overlay_active">
        <div class="popup__center" @click.self="$store.commit('togglePopupText')">
          <div class="popup__window popup__window_blue">
            <button class="close popup__close" @click.self="$store.commit('togglePopupText')"></button>
            <div class="popup__logo"><router-link to="/" class="logo">BANKIM<span>ONLINE</span></router-link></div>
            <div class="popup__scrollcontainer ">
              <div class="popup__scrollcontainer_inner center">
                <div class="text__body"><div>{{ 'go_mortgage_text' | localize }}</div></div>
                <button type="button" class="button button__form" @click="goToMortgage">
                  {{ "go_mortgage" | localize }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import Backlink from '@/components/base/Backlink.vue'
import { clientMixin } from '@/mixins/clientMixin.js'
import { numberMixin } from '@/mixins/numberMixin.js'
import { saveMixin } from '@/mixins/saveMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'

export default {
  name: 'refCredit_payment',
  components: { Backlink },
  mixins: [clientMixin, numberMixin, saveMixin, commonMixin],
  data: () => ({
    price_1: 0,
    aim: null,
    money: null,
    is_more: false,
    success: false
  }),
  created () {
    const refCredit = this.$cookies.get('refCredit') || {}
    this.price_1 = refCredit.credits.reduce((sum, credit) => sum + parseInt(credit.price, 10), 0)
  },
  validations: {
    money: { required }
  },
  methods: {
    select_price () {
      this.money = this.formatNumber(this.price_1)
      this.$refs.money.focus()
      this.is_more = false
      this.checkForm()
    },
    select_more () {
      this.is_more = true
      this.money = null
      this.$refs.aim.focus()
      this.checkForm()
    },
    checkForm (field) {
      if (field) field.$touch()
      this.success = !this.$v.$invalid
    },
    showPopup () {
      if (this.aim === 1 || this.aim === 2) {
        this.$store.commit('togglePopupText', { id: 12, withTitle: false, type: 'popup_info' })
      }
    },
    goToMortgage () {
      // Перейти в ипотеку
      const mortgage = this.$cookies.get('mortgage') || {}
      const refCredit = this.$cookies.get('refCredit') || {}
      mortgage.clients = refCredit.clients || []
      this.$cookies.set('mortgage', mortgage)
      this.$router.push('/mortgage/registration')
    },
    submitHandler () {
      this.money = this.parseNumber(this.money)
      if (this.$v.money.$invalid) {
        this.$v.money.$touch()
        this.$scrollTo('.step', 300, { offset: -150 })
        this.success = false
        return
      }

      const refCredit = this.$cookies.get('refCredit') || {}
      refCredit.money = this.money
      this.$cookies.set('refCredit', refCredit)
      this.saveUserService('refCredit', '/refinance-credit/reg-payment', { amount: parseInt(this.money, 10) })
    }
  }
}
</script>
